<template>
  <a-modal
    v-model="addSDKVisible"
    :title="(isAdd ? '新增' : '修改') + `${title} SDK版本`"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <!-- os -->
      <a-form-model-item
        label="操作系统"
        prop="os"
        :rules="[{ required: true, message: '请选择操作系统', trigger: 'change' }]"
      >
        <a-select :allowClear="true" placeholder="请选择操作系统" v-model="query.os" :disabled="!isAdd">
          <a-select-option :value="1">安卓</a-select-option>
          <a-select-option :value="0">IOS</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- SDK版本号 -->
      <a-form-model-item
        label="SDK版本号"
        prop="version"
        :rules="[{ required: true, message: '请输入SDK版本号', trigger: 'blur' }]"
      >
        <a-input :disabled="!isAdd" placeholder="请输入SDK版本号" v-model.trim="query.version" />
      </a-form-model-item>
      <!-- 更新日志 -->
      <a-form-model-item label="更新日志" prop="modifyLog">
        <a-textarea placeholder="请输入更新日志" v-model="query.modifyLog" :rows="4" />
      </a-form-model-item>
      <!-- 上传SDK -->
      <a-form-model-item
        label="上传SDK"
        prop="sdkDownloadUrl"
        :rules="[{ required: true, message: '请上传文件', trigger: 'change' }]"
      >
        <a-upload
          :key="uploadKey"
          name="file"
          :multiple="false"
          :action="uploadUrl"
          :headers="headers"
          @change="handleChange"
          :remove="handleRemoveFile"
          accept=".zip"
          :defaultFileList="fileList"
        >
          <a-button> <a-icon type="upload" /> 点击上传 </a-button>
        </a-upload>
      </a-form-model-item>
      <!-- 发布状态 -->
      <a-form-model-item label="发布状态" prop="publishSts">
        <a-radio-group class="date_radio" size="small" v-model="query.publishSts">
          <a-radio value="1"> 已发布 </a-radio>
          <a-radio value="0"> 未发布 </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSdk, addSdk } from '@/api/system'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {
        os: undefined,
        version: undefined,
        modifyLog: undefined,
        sdkDownloadUrl: undefined,
        publishSts: '0'
      },
      fileList: [],
      uploadKey: '1',
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/accessCenter/upload'
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    sdkInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        // 添加状态重置表单
        if (this.isAdd) {
          this.query = {
            os: undefined,
            version: undefined,
            modifyLog: undefined,
            sdkDownloadUrl: undefined,
            publishSts: '0'
          }
          this.fileList = []
        }
        // 清除验证
        if (val) {
          this.$nextTick(() => {
            this.$refs.ruleForm.clearValidate()
          })
        }
        // 编辑状态
        if (!val || this.isAdd) {
          return
        }
        this.query = JSON.parse(JSON.stringify(this.sdkInfo))
        // 拼接日志
        this.query.modifyLog = this.query.modifyLogList.join('\n')
        this.query.sdkDownloadUrl &&
          (this.fileList = [
            {
              uid: '1',
              name: 'SDK.zip',
              status: 'done'
            }
          ])
        // 强行渲染
        this.uploadKey = '' + Math.random()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 请求头
    headers () {
      return {
        Authorization: this.token
      }
    },
    addSDKVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      token: (state) => state.user.token,
      role: (state) => state.user.roles[0],
      // 判断角色
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    handleChange (info) {
      if (info.file.status === 'uploading') {
        return
      }
      // 上传成功回调
      if (info.file.status === 'done') {
        // 强行重绘
        this.uploadKey = '' + Math.random()
        this.query.sdkDownloadUrl = info.file.response.data
        const file = info.fileList.length > 1 ? info.fileList[1] : info.fileList[0]
        this.fileList = [file]
      }
    },
    handleRemoveFile () {
      this.fileList = []
      this.query.sdkDownloadUrl = undefined
    },
    handleCancel () {
      this.addSDKVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // 获取日志列表
          this.query.modifyLogList = this.query.modifyLog.split('\n')
          if (this.isAdd) {
            const resp = await addSdk(this.query)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeAppInfo', 'add')
            }
          } else {
            const resp = await updateSdk(this.query)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeAppInfo', 'update')
            }
          }
          // this.addSDKVisible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style></style>

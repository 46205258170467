<template>
  <a-modal v-model="bindPlatVisible" title="绑定广告源SDK" @onCancel="handleCancel" @ok="handleSubmit" :width="700">
    <div class="plat_wrapper">
      <div class="plat_item" v-for="(item, index) in sourceSdkList" :key="index">
        <p class="plat_title">
          {{ item[0].name }}
          <a-button
            type="primary"
            @click="reset(index)"
            size="small"
            style="margin-left: 20px; height: 30px; width: 50px"
          >
            重置
          </a-button>
        </p>
        <a-radio
          class="version_item"
          v-for="(child, i) in item"
          :key="i"
          :checked="child.checked"
          @change="handleSelectVersion($event, index, i)"
        >
          {{ child.version }}
        </a-radio>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { getSourceSdkById, getSourceSdkList, bindingSource } from '@/api/system'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      bindPlatList: [],
      sourceSdkList: []
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    sdkInfo: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      async handler (val) {
        // 获取广告平台列表、已绑定广告平台列表
        if (val) {
          await this.getBindPlatList()
          await this.getSourceSdkList()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    bindPlatVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    ...mapState({
      token: (state) => state.user.token,
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  methods: {
    // 重置
    reset (index) {
      this.sourceSdkList[index].forEach((vi) => {
        vi.checked = false
      })
    },
    async getBindPlatList () {
      const resp = await getSourceSdkById({ id: this.sdkInfo.id })
      this.bindPlatList = resp.data.map((item) => '' + item) || []
    },
    async getSourceSdkList () {
      const { data = {} } = await getSourceSdkList(this.sdkInfo.os)
      const sourceSdkList = []
      if (data) {
        for (const key in data) {
          data[key].forEach((item) => {
            item.id = '' + item.id
            item.checked = false
            // 判断该版本是否被绑定，添加选定状态
            if (this.bindPlatList.includes(item.id)) {
              item.checked = true
            }
          })
          sourceSdkList.push(data[key])
        }
      }
      this.sourceSdkList = sourceSdkList
    },
    handleCancel () {
      this.bindPlatVisible = false
    },
    handleSelectVersion (e, index, i) {
      this.sourceSdkList[index].forEach((vi) => {
        vi.checked = false
      })
      this.sourceSdkList[index][i].checked = true
    },
    async handleSubmit () {
      const checkedVersion = []
      this.sourceSdkList.forEach((item) => {
        item.forEach((child) => {
          if (child.checked) {
            checkedVersion.push(+child.id)
          }
        })
      })
      const resp = await bindingSource({
        medproSdkId: this.sdkInfo.id,
        sourceSdkPackageIds: checkedVersion
      })
      if (resp.code === 200) {
        this.$message.success('绑定成功！')
        this.$emit('changeSdkInfo')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.plat_wrapper {
  max-height: 500px;
  overflow: auto;
}
.plat_item {
  padding: 20px 30px 10px 30px;
  .version_item {
    width: 120px;
    margin: 5px;
  }
}
.plat_title {
  font-size: 14px;
  font-weight: 600;
}
</style>

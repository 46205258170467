<template>
  <div class="sdk-version-table-container">
    <a-table
      class="m_table_cust"
      size="middle"
      :pagination="false"
      :rowKey="(record, index) => index"
      :columns="columns"
      :data-source="dataList"
      tableLayout="fixed"
      :loading="isLoading"
      bordered
    >
      <span slot="os" slot-scope="os">
        {{ +os === 1 ? '安卓' : 'IOS' }}
      </span>
      <div slot="bingdingSourceIds" slot-scope="bingdingSourceIds">
        <div v-if="bingdingSourceIds && bingdingSourceIds.length > 0">
          <span
            v-for="(item, index) in bingdingSourceIds"
            :key="index"
          >{{ getPlatName(item) }}{{ bingdingSourceIds.length > index + 1 ? '、' : '' }}</span
          >
        </div>
        <div v-else>--</div>
      </div>
      <span slot="publishSts" slot-scope="publishSts" :style="{ color: !+publishSts ? 'red' : 'green' }">
        {{ +publishSts === 1 ? '已发布' : '未发布' }}
      </span>
      <div slot="action" slot-scope="record">
        <a-button size="small" class="action-btn" @click="handleUpdateSdk(record)">编辑</a-button>
        <a-button size="small" class="action-btn" type="cust" @click="handleBindPlat(record)">绑定广告平台</a-button>
      </div>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 0"
      show-size-changer
      :current="query.page"
      :total="total"
      @change="handleChangePage"
      :show-total="(total) => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      :pageSize="query.limit"
    />
    <m-empty v-else></m-empty>
    <addSDKModal
      :visible="addSDKVisible"
      :sdkInfo="sdkInfo"
      :isAdd="false"
      @modalCancel="addSDKVisible = false"
      @changeAppInfo="handleAddSDKModal"
    />
    <bindPlatModal
      :visible="bindPlatVisible"
      :sdkInfo="sdkInfo"
      @modalCancel="bindPlatVisible = false"
      @changeSdkInfo="changeSdkInfo"
    />
  </div>
</template>

<script>
import { getSdkPager } from '@/api/system'
import { mapState } from 'vuex'
import mixGetList from '@/mixins/getList'
import addSDKModal from '../components/addSDKModal'
import bindPlatModal from '../components/bindPlatModal'
export default {
  components: { addSDKModal, bindPlatModal },
  data () {
    return {
      query: {
        page: 1,
        limit: 10
      },
      dataList: [],
      total: 0,
      isLoading: false,
      columns: [],
      sdkInfo: {},
      addSDKVisible: false,
      bindPlatVisible: false
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      isAdmin: (state) => state.user.isAdmin,
      title: (state) => state.autoweb.title
    })
  },
  mixins: [mixGetList],
  async created () {
    await this.getPlatList()
    this.getDataList()
    this.columns = [
      {
        title: '系统类型',
        dataIndex: 'os',
        scopedSlots: {
          customRender: 'os'
        }
      },
      {
        title: `${this.title} SDK版本`,
        dataIndex: 'version',
        scopedSlots: {
          customRender: 'version'
        }
      },
      {
        title: `创建时间`,
        dataIndex: 'modifyTime',
        scopedSlots: {
          customRender: 'modifyTime'
        }
      },
      {
        title: `更新时间`,
        dataIndex: 'uploadTime',
        scopedSlots: {
          customRender: 'uploadTime'
        }
      },
      {
        title: `已绑定广告平台`,
        dataIndex: 'bingdingSourceIds',
        scopedSlots: {
          customRender: 'bingdingSourceIds'
        }
      },
      {
        title: `发布状态`,
        dataIndex: 'publishSts',
        scopedSlots: {
          customRender: 'publishSts'
        }
      },
      {
        title: `操作`,
        width: '250px',
        scopedSlots: {
          customRender: 'action'
        }
      }
    ]
  },
  methods: {
    handleQuery (query) {
      this.query = {
        page: 1,
        limit: this.query.limit,
        ...query
      }
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const resp = await getSdkPager(this.query)
      this.dataList = resp.data.items ? resp.data.items : []
      this.total = resp.data.total ? resp.data.total : 0
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    },
    getPlatName (id) {
      return this.platList.find((item) => +item.id === +id).name
    },
    handleUpdateSdk (record) {
      this.sdkInfo = record
      this.addSDKVisible = true
    },
    handleBindPlat (record) {
      this.sdkInfo = record
      this.bindPlatVisible = true
    },
    changeSdkInfo () {
      this.bindPlatVisible = false
      this.getDataList()
    },
    handleAddSDKModal () {
      this.addSDKVisible = false
      this.getDataList()
    }
  }
}
</script>
<style lang="less" scoped>
.sdk-version-table-container {
  padding: 10px;
  .pagination {
    padding: 10px;
  }
  .action-btn {
    margin: 0 10px 0 0;
  }
}
</style>
